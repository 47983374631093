import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { C2foComponentsRootProvider, ErrorBoundary, SnackbarProvider } from '@c2fo/react-components';

import { Router } from './Routes';
import { GlobalErrorPage } from './pages';
import { ServicesProvider } from './services';
import { ApiApolloProvider } from './services/Services.client';

import faviconSm from '../assets/favicon-150x150.png';
import faviconLg from '../assets/favicon-300x300.png';

const App: FC = () => (
  <ErrorBoundary render={() => <GlobalErrorPage />}>
    <SnackbarProvider maxSnack={4}>
      <AppHead />
      <ApiApolloProvider>
        <ServicesProvider>
          <C2foComponentsRootProvider>
            <Router />
          </C2foComponentsRootProvider>
        </ServicesProvider>
      </ApiApolloProvider>
    </SnackbarProvider>
  </ErrorBoundary>
);

const AppHead: React.FC = () => {
  return (
    <Helmet>
      <title>GSTN Admin</title>
      <base href="/" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link href={faviconLg} data-favicon-id="lg" rel="icon" type="image/x-icon" sizes="192x192" />
      <link href={faviconSm} data-favicon-id="sm" rel="icon" type="image/x-icon" sizes="32x32" />
      <link href={faviconLg} data-favicon-id="lg" rel="apple-touch-icon" />
    </Helmet>
  );
};

export default App;
