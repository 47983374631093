import React from 'react';
import { Box, makeStyles, Theme, Liquidity } from '@c2fo/react-components';

import HeaderNavigationBar from '../../components/Atoms/HeaderNavigationBar';

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto 0',
    paddingTop: theme.spacing(2),
    overflow: 'hidden',
    width: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: Liquidity.colors.misc.canvas,
  },
}));

export const BasePage: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <HeaderNavigationBar />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};
