import React from 'react';
import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  NormalizedCacheObject,
  ApolloLink,
  DefaultOptions,
  concat,
} from '@apollo/client';
import { TokenService } from '@c2fo/react-services';
import { config } from '../../config';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const getHttpLink = (url: string) =>
  new HttpLink({
    uri: `${url}/graphql`,
  });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = new TokenService('C2FOAuthToken').getToken();
    let tokenHeader = {};

    tokenHeader = {
      authorization: `Bearer ${token}` || '',
    };
    return {
      headers: {
        ...headers,
        ...tokenHeader,
      },
    };
  });
  return forward(operation);
});

const getClient = (url: string): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, getHttpLink(url)),
    defaultOptions,
  });
};

// Add more such gql client for different end points
const defaultServiceClient: ApolloClient<NormalizedCacheObject> = getClient(config.BASE_API_URL);

const ApiApolloProvider: React.FC = ({ children }) => {
  return <ApolloProvider client={defaultServiceClient}>{children}</ApolloProvider>;
};

export { defaultServiceClient, ApiApolloProvider };
