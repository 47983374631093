import React, { lazy, Suspense, FC } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getAuthenticatedRoute } from '@c2fo/react-components';

import { useServices } from '../services';
import { LoginPage } from '../pages';
import { Routes } from '../constants';
import LoadingScreen from '../components/Atoms/LoadingScreen';

// Don't use lazy loading for the first page (default/home page) or any very frequently used pages.
const HomePage = lazy(() => import('../pages/HomePage'));

const Router: React.FC = () => {
  const { authService } = useServices();
  const suspenseFallback = <LoadingScreen />;

  const AuthenticatedRoute = getAuthenticatedRoute({
    isAuthenticated: () => authService.isAuthenticated(),
    redirectTo: '/',
  });

  return (
    <Suspense fallback={suspenseFallback}>
      <BrowserRouter basename={'#'}>
        <Switch>
          <Route exact path={Routes.Login} component={LoginPage} />

          <AuthenticatedRoute exact path={Routes.HomePage} component={HomePage} />

          {/* Default page to redirect in case of no route match */}
          <Redirect to={Routes.HomePage} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export { Router };
