import React from 'react';
import { makeStyles, Theme, Logo, Liquidity, PrimaryButton, TypeHelper } from '@c2fo/react-components';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../constants';
import { useServices } from '../../../services';

const barHeight = 18;
const barHeightSm = 88;
const useStyles = makeStyles<Theme>((theme) => ({
  placeholderElement: {
    height: barHeight,
    [theme.breakpoints.up('sm')]: {
      height: barHeightSm,
    },
  },
  bar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    color: Liquidity.colors.primary.deepGreen,
    zIndex: 2,
  },
  container: {
    backgroundColor: theme.palette.common.white,
    color: Liquidity.colors.primary.deepGreen,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: theme.breakpoints.values.lg,
    padding: theme.spacing(0, 3),
    margin: '0 auto 0',
  },
  logo: {
    height: 16,
    [theme.breakpoints.up('sm')]: {
      height: 24,
    },
  },
  logoLink: {
    lineHeight: 0,
    paddingRight: theme.spacing(2),
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  link: {
    color: Liquidity.colors.primary.deepGreen,
    fontSize: 'inherit',
    fontWeight: 'bold',
    padding: theme.spacing(2, 1),
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  activeNav: {
    backgroundColor: Liquidity.colors.misc.canvas,
    borderBottom: `2px solid ${Liquidity.colors.primary.brightGreen}`,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  username: {
    padding: theme.spacing(2, 1),
  },
}));

const TESTID_ROOT = 'Navigation';
export const testIds = {
  logo: `${TESTID_ROOT}:logo`,
  homePageLink: `${TESTID_ROOT}:orgPageLink`,
  userEmail: `${TESTID_ROOT}:userEmail`,
  logoutButton: `${TESTID_ROOT}:logoutButton`,
} as const;

const HeaderNavigationBar: React.FC = (props) => {
  const classes = useStyles({});
  const { tokenService } = useServices();

  const email = tokenService.getTokenContent()?.payload.user.emailAddress;

  const onLogout = () => {
    tokenService.clearToken();
    window.location.replace(`${window.location.origin + Routes.HomePage}`);
  };

  const tabs = [
    {
      displayName: 'Home',
      route: Routes.HomePage,
      testId: testIds.homePageLink,
    },
  ];

  return (
    <div className={classes.placeholderElement}>
      <nav className={classes.bar}>
        <div className={classes.container}>
          <div className={classes.flex}>
            <div className={classes.logoContainer}>
              <NavLink to="/" className={classes.logoLink} data-testid={testIds.logo}>
                <Logo className={classes.logo} />
              </NavLink>
            </div>
            {tabs.map((item) => (
              <NavLink
                key={item.testId}
                to={item.route}
                className={classes.link}
                activeClassName={classes.activeNav}
                data-testid={item.testId}
              >
                {item.displayName}
              </NavLink>
            ))}
          </div>
          <div className={classes.flex}>
            <TypeHelper align="center" style={{ paddingRight: '1rem' }} data-testid={testIds.userEmail}>
              {email}
            </TypeHelper>
            <div>
              <PrimaryButton onClick={onLogout} data-testid={testIds.logoutButton}>
                Log out
              </PrimaryButton>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderNavigationBar;
