import React from 'react';
import { makeStyles, Theme, Liquidity, Box } from '@c2fo/react-components';

import c2foLogo from '../../../../assets/svg/loading-logo.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    backgroundColor: Liquidity.colors.misc.canvas,
  },
  bouncyLogo: {
    width: '12rem',
    height: '8rem',
    position: 'relative',
  },
}));

const LoadingScreen: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <img alt="Loading" data-testid="loading-screen:c2fo-logo" className={classes.bouncyLogo} src={c2foLogo} />
    </Box>
  );
};

export default LoadingScreen;
