import React, { useRef } from 'react';
import {
  Box,
  PrimaryButton,
  makeStyles,
  Paper,
  TextField,
  Theme,
  TypeBase,
  TypeSectionHeader,
} from '@c2fo/react-components';

import { useServices } from '../../services';
import { config } from '../../../config';

const useStyles = makeStyles<Theme>((theme) => ({
  actionContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
  },
  button: {
    marginLeft: 'auto',
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  textInput: {
    flex: 1,
    margin: theme.spacing(0, 1),
  },
}));

export const DevLogin: React.FC = () => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { authService, tokenService } = useServices();

  const onSetTokenClick = () => {
    if (!inputRef.current) return;
    tokenService.setToken(inputRef.current.value);
    if (authService.isAuthenticated()) {
      window.location.replace('/');
    }
  };

  return (
    <form onSubmit={onSetTokenClick}>
      <Box>
        <Paper className={classes.formContainer}>
          <TypeSectionHeader>Dev JWT Login</TypeSectionHeader>
          <TypeBase>
            Open up{' '}
            <a href={config.ADMIN_APP} target="_blank" rel="noopener noreferrer">
              admin
            </a>{' '}
            and login. Then open your console and type{' '}
            <TypeBase component="span" isEmphasis>
              copy(localStorage.token)
            </TypeBase>
            . Right click, paste in the below text box.
          </TypeBase>
          <Box className={classes.actionContainer}>
            <TextField className={classes.textInput} inputRef={inputRef} />
            <PrimaryButton className={classes.button} onClick={onSetTokenClick}>
              Set Token
            </PrimaryButton>
          </Box>
        </Paper>
      </Box>
    </form>
  );
};
