import {
  makeServices,
  ActiveDirectoryAuthService,
  HttpService,
  TokenService,
  CurrencyService,
} from '@c2fo/react-services';
import { userAuthServiceConfig } from '@c2fo/common-config';

/**
 * Create service singletons.
 */
const tokenService = new TokenService('C2FOAuthToken');
const httpService = new HttpService(tokenService);
const authService = new ActiveDirectoryAuthService(
  tokenService,
  httpService,
  userAuthServiceConfig.USER_AUTH_SERVICE_URL,
);
const currencyService = new CurrencyService();

export interface Services {
  authService: ActiveDirectoryAuthService;
  tokenService: TokenService;
  currencyService: CurrencyService;
}

export const { ServicesProvider, useServices } = makeServices<Services>({
  authService,
  tokenService,
  currencyService,
});
