import React from 'react';
import { TypeBase, makeStyles, Paper } from '@c2fo/react-components';
import { BasePage } from '../BasePage/BasePage';

/**
 * As this is global app error boundary, we cannot rely on
 * the Material UI `theme` property being set appropriately here.
 */
const useStyles = makeStyles(() => ({
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: 24,
    borderRadius: 8,
  },
}));

export const GlobalErrorPage: React.FC = () => {
  const classes = useStyles({});

  return (
    <BasePage>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <TypeBase isError align="center">
            The application has encountered an unknown error.
          </TypeBase>
        </Paper>
      </div>
    </BasePage>
  );
};
