import { env } from '@c2fo/common-config';

export const config = {
  ADMIN_APP: env.getString('LEGACY_C2FO_ADMIN_UI_URL') || 'http://localhost:8088',
  IS_DEVELOPMENT: env.getString('NODE_ENV') === 'development' || false,

  /**
   * Add url constants here imported from netlify.toml as:
   * BASE_API_URL: env.getString('GST_BASE_URL') || 'http://localhost:8080',
   */
  BASE_API_URL: env.getString('GST_BASE_URL') || 'http://localhost:8080',

  GSTIN_LIST_PAGING_LIMIT: env.getInteger('GSTIN_LIST_PAGING_LIMIT') || 5000,
};
