// For customTable.js

import { TextInputFormElementProps } from '../types/DynamicForm.schema';

export const defaultRowPerPage = 20;
export const defaultInitialPageNumber = 1;
export const noCellData = '--';

export const TYPING_DEBOUNCE = 300;

// For Terms and Condition Modal.

// TODO: Replace it once we get the confirmation and data from legal team.
// Link to track above TODO :https://honeycomb.jira.com/browse/GSTN-105
export const termsAndConditions = `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
Lorem Ipsum has been the
industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
scrambled
it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
including versions of Lorem Ipsum. <br>
<br>
Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum has
been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of
type and
scrambled it to make a type specimen book. <br>
  Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s.
`;

export const termsAndConditionHeading = 'Description';
export const currentTimeStamp = new Date();

// Custom colors used on GSTSummary Page.

export const activationDeactivationHeadingColor = '#A39898';
export const panNoCardTextColor = '#A4A0A0';
export const tncIconColor = '#000000';

export const sessionActivateConfig = {
  gstinNo: {
    label: 'GSTIN',
    apiKey: 'gstinNo',
    required: true,
    value: '',
    error: null,
  } as TextInputFormElementProps,
  userName: {
    label: 'Username',
    apiKey: 'userName',
    required: true,
    value: '',
    error: null,
  } as TextInputFormElementProps,
};
export const formOTPSubmitConfig = {
  gstinNo: {
    label: 'GSTIN',
    apiKey: 'gstinNo',
    required: true,
    value: '',
    error: null,
  } as TextInputFormElementProps,
  otp: {
    label: 'OTP',
    apiKey: 'otp',
    required: true,
    value: '',
    error: null,
  } as TextInputFormElementProps,
};
