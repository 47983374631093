import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useServices } from '../../services';
import { config } from '../../../config';
import { DevLogin } from './DevLogin';
import { Routes } from '../../constants';

export const { ADMIN_APP } = config;

export function redirectToAdminLogin() {
  const redirectURL = `${ADMIN_APP}/login?redirect-to=${encodeURIComponent(
    window.location.href,
  )}&tokenQueryParam=authorization`;
  window.location.replace(redirectURL);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const LoginPage: React.FC = () => {
  const { authService, tokenService } = useServices();
  const query = useQuery();

  const authorization = query.get('authorization');
  if (authorization) {
    tokenService.setToken(authorization);
  }

  if (authService.isAuthenticated()) {
    return <Redirect to={Routes.HomePage} />;
  }

  tokenService.clearToken();
  if (config.IS_DEVELOPMENT) {
    return <DevLogin />;
  }
  redirectToAdminLogin();
  // TODO: Update this text with something meaning full and related to project
  return <div>You are not signed in. Routing to c2fo admin app login page.</div>;
};
